import logo from './logo.svg';
import './App.css';
import InvitePage from './pages/InvitePage';
import LandingPage from './pages/LandingPage';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/invite" element={<InvitePage/>} />
        <Route path="/" element={<LandingPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
