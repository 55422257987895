import React, {useState, useEffect} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

export default function InvitePage() {


  const [searchParams, setSearchParams] = useSearchParams();
  const [party, setParty] = useState({
    creator_name: "",
    image_url: "",
    location: "",
    party_name: ""
  })

  const [photoList, setPhotoList] = useState([]);

  useEffect(() => {
    let url = "https://hqxpsltrcbznycezspik.supabase.co/rest/v1/rpc/invite?party="
    let apiKey = "&apikey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhxeHBzbHRyY2J6bnljZXpzcGlrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDc5NjI1MTksImV4cCI6MjAyMzUzODUxOX0.hPehaln7I9Ml7Jb4FQvhYwNmq8Z4uOGUihgr5wQG6oM"

    let party = searchParams.get("party");


    axios.get(url + party + apiKey).then(response => {
      // console.log("RESPONSE")
      // console.log(response.data);
      setParty(response.data)
      setPhotoList([{
        img: response.data.image_url,
        title: response.data.party_name
      }])

    }).catch(error => {
      alert("There was an error fetching this party");
    })

  }, []);

  return (
    <div>
        <Helmet>
          <title>You have been invited to a Pay Party!</title>
          <meta property="og:type" content="website" />
          <meta property="og:title" content="You have been invited to a Pay Party!" />
          <meta property="og:description" content="Click now to download PayParty and get this party started!" />
          <meta property="og:image" content={party.image_url} />
        </Helmet>
        <ImageList sx={{ width: 500, height: 450 }} cols={1} rowHeight={164}>
        {photoList.map((item) => (
            <ImageListItem key={item.img}>
            <img
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
            />
            </ImageListItem>
        ))}
        </ImageList>

        <text>You've been invited to the '{party.party_name}' party!</text>
    </div>
    
  );
}
//TODO FIX DATABASE TO RETURN NAME INSTEAD OF JUST PHONE NUMBER
const itemData = [
  {
    img: 'https://hqxpsltrcbznycezspik.supabase.co/storage/v1/object/public/party_pic/86E2F761-0D2C-4EB6-999D-683FA1DE112B.jpg',
    title: 'Breakfast',
  }
];