import * as React from 'react';


export default function LandingPage() {
  return (
    <div>
        <text>Landing Page!</text>
    </div>
    
  );
}

